import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom'
import './App.css'
import Home from './Pages/Home/Home'
import Header from './Components/Header/Header'
import Footer from './Components/Footer/Footer'
import About from './Pages/About'
import Projects from './Pages/Projects'
import Contact from './Pages/Contact'
import Resume from './Pages/Resume'
import BlogApp from './Pages/BlogApp'
import Preloader from './Components/Preloader/Preloader';



const App = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Show the preloader for 2 seconds whenever the URL changes
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, [location]);
  return (
    <>
    {loading && <Preloader />}
    <Header />
    <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/about' element={<About />}/>
          <Route path='/projects' element={<Projects />}/>
          <Route path='/contact' element={<Contact />}/>
          <Route path='/resume' element={<Resume />}/>
          <Route path='/blogapp' element={<BlogApp />}/>
        </Routes>
    <Footer />
    </>
  )
}

export default App