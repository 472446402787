import React from 'react'
import { NavLink } from 'react-router-dom'
import './Home.css'
import Underline2 from '../../Images/underline.png'
import homeImage from '../../Images/himali.jpg'






function Home() {
    return (
        <>
            <main>
                <div className="home-container">
                    <div className="homebox2">
                        <img src={homeImage} alt="" className='floating'/>
                    </div>
                    <div className="homebox1">
                        <h2>Hii, I'm</h2>
                        <img src={Underline2} alt="" />
                        <h1>Himali Chavan</h1>
                        
                        <p>I love making the things that
                            help others do their thing. Let's Discuss , Design and
                            Digitalize your thoughts.
                        </p>
                        <button><NavLink to="/resume" className={() => `navlink`}>View Resume</NavLink></button>
                    </div>
                    <div className="homebox3">
                        <div className="socials">
                            <i class="fa-brands fa-facebook"></i>
                            <i class="fa-brands fa-instagram"></i>
                            <i class="fa-brands fa-x-twitter"></i>
                            <i class="fa-brands fa-youtube"></i>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Home
