import React, { useState }from 'react'
import Underline2 from '../Images/underline.png'
import himu from '../Images/himali.jpg'

const Resume = () => {
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Email:', email)
        window.open(process.env.PUBLIC_URL + '/himali-chavan-resume.pdf', '_blank');
    };
    return (
        <div className="projects" id='projects'>
            <div className="head">
                <h2>My Resume</h2>
                <img src={Underline2} className='line' alt="" />
            </div>
            <div className="resumebox">
                <img src={himu} alt="" />
                <form onSubmit={handleSubmit} className='resumeform'>
                    <input type="email"
                        id="email"
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)} placeholder='Your Email' />
                    <button type='submit'>View Resume</button>
                </form>
            </div>
        </div>
    )
}

export default Resume