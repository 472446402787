import React from 'react'
import BlogHome from '../Images/blogHome.png'
import Signup from '../Images/signup.png'
import Login from '../Images/login.png'
import AddBlog from '../Images/addblog.png'
import BlogPage from '../Images/blogpage.png'
import Underline from '../Images/line.png'

const BlogApp = () => {
    return (
        <div className="blogapp">
            <h2>Blog App</h2>
            <img src={Underline} className='line' alt="" />
            <p>
                Created a Full Stack Web App with Node JS, Express JS, EJS, MongoDB.
            </p>

            <div class="blogGallery">
                <div class="gallery-item">
                    <img src={BlogHome} alt="Page 1" />
                    <div class="name-container">Home Page</div>
                </div>
                <div class="gallery-item">
                    <img src={Signup} alt="Page 2" />
                    <div class="name-container">Create Account Page</div>
                </div>
                <div class="gallery-item">
                    <img src={Login} alt="Page 3" />
                    <div class="name-container">Log In Page</div>
                </div>
                <div class="gallery-item">
                    <img src={AddBlog} alt="Page 3" />
                    <div class="name-container">Add Blog Page</div>
                </div>
                <div class="gallery-item">
                    <img src={BlogPage} alt="Page 3" />
                    <div class="name-container">Single Blog Page</div>
                </div>
            </div>

        </div>
    )
}

export default BlogApp