import React from 'react'
import { Link } from 'react-router-dom'
import Underline from '../Images/line.png'
import Underline2 from '../Images/underline.png'
import Project3 from '../Images/photos.jpg'
import Project4 from '../Images/project4.png'
import Project5 from '../Images/project5.png'
import Project6 from '../Images/project6.png'
import Project7 from '../Images/project7.png'
import Project8 from '../Images/project8.png'
import Project10 from '../Images/blogApp2.png'

import MIB from '../Images/modern-indian-bistro.png'
import Ivory from '../Images/ivory-dental-clinic.png'
import PillaiCafe from '../Images/pillai-cafe.png'
import Tawa from '../Images/tawa-restaurant.png'

const Projects = () => {
    return (
        <>
            <div className="head">
                <h2>My Projects</h2>
                <img src={Underline2} className='line' alt="" />
            </div>
            <div className="projectsContainer">
                <div className="project">
                    <div className="project-content">
                        <h3>Modern Indian Bistro</h3>
                        <img src={Underline} alt="" />
                        <p>A modern cafe website using React.js, featuring dynamic menus, responsive design, and seamless navigation</p>
                        <button><Link to="https://modernindianbistro.com/" target='himali'>View Project</Link></button>
                    </div>
                    <div className="projectImage">
                        <img src={MIB} alt="" />
                    </div>
                </div>
                <div className="project">
                    <div className="project-content">
                        <h3>Ivory Dental Clinic</h3>
                        <img src={Underline} alt="" />
                        <p>A modern dentist clinic website using React.js, featuring dynamic menus, responsive design, and seamless navigation</p>
                        <button><Link to="https://ivorydentalcare.net.in/" target='himali'>View Project</Link></button>
                    </div>
                    <div className="projectImage">
                        <img src={Ivory} alt="" />
                    </div>
                </div>
                <div className="project">
                    <div className="project-content">
                        <h3>Pillai Cafe</h3>
                        <img src={Underline} alt="" />
                        <p>A modern south indian cafe website using React.js, featuring dynamic menus, responsive design, and seamless navigation</p>
                        <button><Link to="https://pillaicafe.himalichavan.in/" target='himali'>View Project</Link></button>
                    </div>
                    <div className="projectImage">
                        <img src={PillaiCafe} alt="" />
                    </div>
                </div>
                <div className="project">
                    <div className="project-content">
                        <h3>Tawa Restauarnt</h3>
                        <img src={Underline} alt="" />
                        <p>A modern restaurant website using React.js, featuring dynamic menus, responsive design, and seamless navigation</p>
                        <button><Link to="https://tawa.himalichavan.in/" target='himali'>View Project</Link></button>
                    </div>
                    <div className="projectImage">
                        <img src={Tawa} alt="" />
                    </div>
                </div>
            </div>
            <div className="projects" id='projects'>
            <div className="box">
                    <img src={Project8} alt="" />
                    <div className="box-content">
                        <h3>Builder Landing Page</h3>
                        <p>Designed a builder landing page with a professional layout, showcasing services and client testimonials effectively.</p>
                        <button><Link to="https://landingpage.himalichavan.in/" target='himali'>View Project</Link></button>
                    </div>
                </div>
                <div className="box">
                    <img src={Project10} alt="" />
                    <div className="box-content">
                        <h3>Blog App</h3>
                        <p>Developed a full-stack blog app using Node.js and Express, featuring CRUD operations and user authentication.</p>
                        <button><Link to="/blogapp" target='himali'>View Project</Link></button>
                    </div>
                </div>
                <div className="box">
                    <img src={Project3} alt="" />
                    <div className="box-content">
                        <h3>Photo Studio</h3>
                        <p>A Modern Photography Studio Website with CSS Grid and Responsive design</p>
                        <button><Link to="https://photostudio.himalichavan.in/" target='himali'>View Project</Link></button>
                    </div>
                </div>
                <div className="box">
                    <img src={Project4} alt="" />
                    <div className="box-content">
                        <h3>Joke Generator</h3>
                        <p>A Joke Generator using API fetching, delivering random jokes with a simple, user-friendly interface.</p>
                        <button><Link to="https://jokegenerator.himalichavan.in/" target='himali'>View Project</Link></button>
                    </div>
                </div>
                <div className="box">
                    <img src={Project5} alt="" />
                    <div className="box-content">
                        <h3>Weather App</h3>
                        <p>A weather app using API fetching, providing real-time forecasts with a clean, intuitive design.</p>
                        <button><Link to="https://weatherapp.himalichavan.in/" target='himali'>View Project</Link></button>
                    </div>
                </div>
                <div className="box">
                    <img src={Project6} alt="" />
                    <div className="box-content">
                        <h3>Quotes Generator</h3>
                        <p>A Quotes Generator using API fetching, displaying random inspirational quotes with a minimalist interface.</p>
                        <button>View</button>
                    </div>
                </div>
                {/* <div className="box">
                    <img src={Project7} alt="" />
                    <div className="box-content">
                        <h3>Rock Paper Scissor</h3>
                        <p>A Rock Paper Scissors app using vanilla JavaScript, featuring basic gameplay and intuitive UI.</p>
                        <button>View</button>
                    </div>
                </div> */}
            </div>

        </>
    )
}

export default Projects